<template>
    <!-- 小程序 -->
    <div class="theApplet">
        <AlcFour></AlcFour>
        <!-- 内容一 -->
        <AlcOne></AlcOne>
        <!-- 内容二 -->
        <AlcTwo></AlcTwo>
        <!-- 内容三 -->
        <AlcThree></AlcThree>
        <!-- App应用领域 -->
        <AppField></AppField>
    </div>
</template>

<script>
import AlcFour from "./AlContent/AlcFour.vue";
import AlcOne from "./AlContent/AlcOne.vue";
import AlcTwo from "./AlContent/AlcTwo.vue";
import AlcThree from "./AlContent/AlcThree.vue";
import AppField from "@/components/content/Homes/AppField/AppField.vue";
export default {
    name: "TheApplet",
    components: {
        AlcOne,
        AlcTwo,
        AlcThree,
        AlcFour,
        AppField,
    },
};
</script>

<style lang="less" scoped>
.theApplet {
    min-width: 1423px;
    margin: 0 auto;
    // min-width: 1423px;
}
</style>
