<template>
    <div class="appFourteen">
        <div class="aftBox">
            <!-- 标题 -->
            <div class="aftTitle">客户案例</div>
            <!-- 案例列表 -->

            <div class="phFList">
                <template v-for="el in list">
                    <vy-phone :key="el.id" :element="el" objectFit="contain"></vy-phone>
                </template>
            </div>
        </div>
    </div>
</template>

<script>
import axios from "axios";
import VyPhone from "@/util/VyPhone.vue";

export default {
    components: { VyPhone },
    name: "AlcTwo",
    data() {
        return {
            list: [],
        };
    },
    async created() {
        let { data } = await axios.get("/api/project/applet");
        this.list = data;
    },
    methods: {
        goDetail({ id, ueditorData, project_url }) {
            if (ueditorData == "" || ueditorData == "<p><br></p>" || ueditorData == null) {
                if (!project_url) return;
                if (project_url.indexOf("http") >= 0 || project_url.indexOf("https") >= 0) {
                    window.open(project_url);
                } else {
                    window.open("http://" + project_url);
                }
                return;
            }
            this.$router.push({
                name: "listDetail",
                params: { id },
            });
        },
    },
};
</script>

<style lang="less" scoped>
.appFourteen {
    width: 100%;
    background-color: white;
    .aftBox {
        width: 1280px;
        height: 100%;
        margin: 0 auto;
        // overflow: hidden;
        // 标题
        .aftTitle {
            width: 20%;
            height: 50px;
            line-height: 50px;
            text-align: center;
            font-size: 36px;
            margin: 0 auto;
            margin-top: 50px;
        }
        // 案例列表
        .phFList {
            width: 100%;
            margin-top: 90px;
            display: flex;
            justify-content: space-evenly;
            /deep/ .vy-phone {
                width: 300px;
                height: 555px;
            }
        }
    }
}
</style>
